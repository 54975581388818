import * as _document2 from "global/document";

var _document = "default" in _document2 ? _document2.default : _document2;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

/**
 * Copyright 2013 vtt.js Contributors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/* -*- Mode: Java; tab-width: 2; indent-tabs-mode: nil; c-basic-offset: 2 -*- */

/* vim: set shiftwidth=2 tabstop=2 autoindent cindent expandtab: */
var document = _document;

var _objCreate = Object.create || function () {
  function F() {}

  return function (o) {
    if (arguments.length !== 1) {
      throw new Error("Object.create shim only accepts one parameter.");
    }

    F.prototype = o;
    return new F();
  };
}(); // Creates a new ParserError object from an errorData object. The errorData
// object should have default code and message properties. The default message
// property can be overriden by passing in a message parameter.
// See ParsingError.Errors below for acceptable errors.


function ParsingError(errorData, message) {
  (this || _global).name = "ParsingError";
  (this || _global).code = errorData.code;
  (this || _global).message = message || errorData.message;
}

ParsingError.prototype = _objCreate(Error.prototype);
ParsingError.prototype.constructor = ParsingError; // ParsingError metadata for acceptable ParsingErrors.

ParsingError.Errors = {
  BadSignature: {
    code: 0,
    message: "Malformed WebVTT signature."
  },
  BadTimeStamp: {
    code: 1,
    message: "Malformed time stamp."
  }
}; // Try to parse input as a time stamp.

function parseTimeStamp(input) {
  function computeSeconds(h, m, s, f) {
    return (h | 0) * 3600 + (m | 0) * 60 + (s | 0) + (f | 0) / 1000;
  }

  var m = input.match(/^(\d+):(\d{1,2})(:\d{1,2})?\.(\d{3})/);

  if (!m) {
    return null;
  }

  if (m[3]) {
    // Timestamp takes the form of [hours]:[minutes]:[seconds].[milliseconds]
    return computeSeconds(m[1], m[2], m[3].replace(":", ""), m[4]);
  } else if (m[1] > 59) {
    // Timestamp takes the form of [hours]:[minutes].[milliseconds]
    // First position is hours as it's over 59.
    return computeSeconds(m[1], m[2], 0, m[4]);
  } else {
    // Timestamp takes the form of [minutes]:[seconds].[milliseconds]
    return computeSeconds(0, m[1], m[2], m[4]);
  }
} // A settings object holds key/value pairs and will ignore anything but the first
// assignment to a specific key.


function Settings() {
  (this || _global).values = _objCreate(null);
}

Settings.prototype = {
  // Only accept the first assignment to any key.
  set: function (k, v) {
    if (!this.get(k) && v !== "") {
      (this || _global).values[k] = v;
    }
  },
  // Return the value for a key, or a default value.
  // If 'defaultKey' is passed then 'dflt' is assumed to be an object with
  // a number of possible default values as properties where 'defaultKey' is
  // the key of the property that will be chosen; otherwise it's assumed to be
  // a single value.
  get: function (k, dflt, defaultKey) {
    if (defaultKey) {
      return this.has(k) ? (this || _global).values[k] : dflt[defaultKey];
    }

    return this.has(k) ? (this || _global).values[k] : dflt;
  },
  // Check whether we have a value for a key.
  has: function (k) {
    return k in (this || _global).values;
  },
  // Accept a setting if its one of the given alternatives.
  alt: function (k, v, a) {
    for (var n = 0; n < a.length; ++n) {
      if (v === a[n]) {
        this.set(k, v);
        break;
      }
    }
  },
  // Accept a setting if its a valid (signed) integer.
  integer: function (k, v) {
    if (/^-?\d+$/.test(v)) {
      // integer
      this.set(k, parseInt(v, 10));
    }
  },
  // Accept a setting if its a valid percentage.
  percent: function (k, v) {
    var m;

    if (m = v.match(/^([\d]{1,3})(\.[\d]*)?%$/)) {
      v = parseFloat(v);

      if (v >= 0 && v <= 100) {
        this.set(k, v);
        return true;
      }
    }

    return false;
  }
}; // Helper function to parse input into groups separated by 'groupDelim', and
// interprete each group as a key/value pair separated by 'keyValueDelim'.

function parseOptions(input, callback, keyValueDelim, groupDelim) {
  var groups = groupDelim ? input.split(groupDelim) : [input];

  for (var i in groups) {
    if (typeof groups[i] !== "string") {
      continue;
    }

    var kv = groups[i].split(keyValueDelim);

    if (kv.length !== 2) {
      continue;
    }

    var k = kv[0].trim();
    var v = kv[1].trim();
    callback(k, v);
  }
}

function parseCue(input, cue, regionList) {
  // Remember the original input if we need to throw an error.
  var oInput = input; // 4.1 WebVTT timestamp

  function consumeTimeStamp() {
    var ts = parseTimeStamp(input);

    if (ts === null) {
      throw new ParsingError(ParsingError.Errors.BadTimeStamp, "Malformed timestamp: " + oInput);
    } // Remove time stamp from input.


    input = input.replace(/^[^\sa-zA-Z-]+/, "");
    return ts;
  } // 4.4.2 WebVTT cue settings


  function consumeCueSettings(input, cue) {
    var settings = new Settings();
    parseOptions(input, function (k, v) {
      switch (k) {
        case "region":
          // Find the last region we parsed with the same region id.
          for (var i = regionList.length - 1; i >= 0; i--) {
            if (regionList[i].id === v) {
              settings.set(k, regionList[i].region);
              break;
            }
          }

          break;

        case "vertical":
          settings.alt(k, v, ["rl", "lr"]);
          break;

        case "line":
          var vals = v.split(","),
              vals0 = vals[0];
          settings.integer(k, vals0);
          settings.percent(k, vals0) ? settings.set("snapToLines", false) : null;
          settings.alt(k, vals0, ["auto"]);

          if (vals.length === 2) {
            settings.alt("lineAlign", vals[1], ["start", "center", "end"]);
          }

          break;

        case "position":
          vals = v.split(",");
          settings.percent(k, vals[0]);

          if (vals.length === 2) {
            settings.alt("positionAlign", vals[1], ["start", "center", "end"]);
          }

          break;

        case "size":
          settings.percent(k, v);
          break;

        case "align":
          settings.alt(k, v, ["start", "center", "end", "left", "right"]);
          break;
      }
    }, /:/, /\s/); // Apply default values for any missing fields.

    cue.region = settings.get("region", null);
    cue.vertical = settings.get("vertical", "");

    try {
      cue.line = settings.get("line", "auto");
    } catch (e) {}

    cue.lineAlign = settings.get("lineAlign", "start");
    cue.snapToLines = settings.get("snapToLines", true);
    cue.size = settings.get("size", 100); // Safari still uses the old middle value and won't accept center

    try {
      cue.align = settings.get("align", "center");
    } catch (e) {
      cue.align = settings.get("align", "middle");
    }

    try {
      cue.position = settings.get("position", "auto");
    } catch (e) {
      cue.position = settings.get("position", {
        start: 0,
        left: 0,
        center: 50,
        middle: 50,
        end: 100,
        right: 100
      }, cue.align);
    }

    cue.positionAlign = settings.get("positionAlign", {
      start: "start",
      left: "start",
      center: "center",
      middle: "center",
      end: "end",
      right: "end"
    }, cue.align);
  }

  function skipWhitespace() {
    input = input.replace(/^\s+/, "");
  } // 4.1 WebVTT cue timings.


  skipWhitespace();
  cue.startTime = consumeTimeStamp(); // (1) collect cue start time

  skipWhitespace();

  if (input.substr(0, 3) !== "-->") {
    // (3) next characters must match "-->"
    throw new ParsingError(ParsingError.Errors.BadTimeStamp, "Malformed time stamp (time stamps must be separated by '-->'): " + oInput);
  }

  input = input.substr(3);
  skipWhitespace();
  cue.endTime = consumeTimeStamp(); // (5) collect cue end time
  // 4.1 WebVTT cue settings list.

  skipWhitespace();
  consumeCueSettings(input, cue);
} // When evaluating this file as part of a Webpack bundle for server
// side rendering, `document` is an empty object.


var TEXTAREA_ELEMENT = document.createElement && document.createElement("textarea");
var TAG_NAME = {
  c: "span",
  i: "i",
  b: "b",
  u: "u",
  ruby: "ruby",
  rt: "rt",
  v: "span",
  lang: "span"
}; // 5.1 default text color
// 5.2 default text background color is equivalent to text color with bg_ prefix

var DEFAULT_COLOR_CLASS = {
  white: "rgba(255,255,255,1)",
  lime: "rgba(0,255,0,1)",
  cyan: "rgba(0,255,255,1)",
  red: "rgba(255,0,0,1)",
  yellow: "rgba(255,255,0,1)",
  magenta: "rgba(255,0,255,1)",
  blue: "rgba(0,0,255,1)",
  black: "rgba(0,0,0,1)"
};
var TAG_ANNOTATION = {
  v: "title",
  lang: "lang"
};
var NEEDS_PARENT = {
  rt: "ruby"
}; // Parse content into a document fragment.

function parseContent(window, input) {
  function nextToken() {
    // Check for end-of-string.
    if (!input) {
      return null;
    } // Consume 'n' characters from the input.


    function consume(result) {
      input = input.substr(result.length);
      return result;
    }

    var m = input.match(/^([^<]*)(<[^>]*>?)?/); // If there is some text before the next tag, return it, otherwise return
    // the tag.

    return consume(m[1] ? m[1] : m[2]);
  }

  function unescape(s) {
    TEXTAREA_ELEMENT.innerHTML = s;
    s = TEXTAREA_ELEMENT.textContent;
    TEXTAREA_ELEMENT.textContent = "";
    return s;
  }

  function shouldAdd(current, element) {
    return !NEEDS_PARENT[element.localName] || NEEDS_PARENT[element.localName] === current.localName;
  } // Create an element for this tag.


  function createElement(type, annotation) {
    var tagName = TAG_NAME[type];

    if (!tagName) {
      return null;
    }

    var element = window.document.createElement(tagName);
    var name = TAG_ANNOTATION[type];

    if (name && annotation) {
      element[name] = annotation.trim();
    }

    return element;
  }

  var rootDiv = window.document.createElement("div"),
      current = rootDiv,
      t,
      tagStack = [];

  while ((t = nextToken()) !== null) {
    if (t[0] === "<") {
      if (t[1] === "/") {
        // If the closing tag matches, move back up to the parent node.
        if (tagStack.length && tagStack[tagStack.length - 1] === t.substr(2).replace(">", "")) {
          tagStack.pop();
          current = current.parentNode;
        } // Otherwise just ignore the end tag.


        continue;
      }

      var ts = parseTimeStamp(t.substr(1, t.length - 2));
      var node;

      if (ts) {
        // Timestamps are lead nodes as well.
        node = window.document.createProcessingInstruction("timestamp", ts);
        current.appendChild(node);
        continue;
      }

      var m = t.match(/^<([^.\s/0-9>]+)(\.[^\s\\>]+)?([^>\\]+)?(\\?)>?$/); // If we can't parse the tag, skip to the next tag.

      if (!m) {
        continue;
      } // Try to construct an element, and ignore the tag if we couldn't.


      node = createElement(m[1], m[3]);

      if (!node) {
        continue;
      } // Determine if the tag should be added based on the context of where it
      // is placed in the cuetext.


      if (!shouldAdd(current, node)) {
        continue;
      } // Set the class list (as a list of classes, separated by space).


      if (m[2]) {
        var classes = m[2].split(".");
        classes.forEach(function (cl) {
          var bgColor = /^bg_/.test(cl); // slice out `bg_` if it's a background color

          var colorName = bgColor ? cl.slice(3) : cl;

          if (DEFAULT_COLOR_CLASS.hasOwnProperty(colorName)) {
            var propName = bgColor ? "background-color" : "color";
            var propValue = DEFAULT_COLOR_CLASS[colorName];
            node.style[propName] = propValue;
          }
        });
        node.className = classes.join(" ");
      } // Append the node to the current node, and enter the scope of the new
      // node.


      tagStack.push(m[1]);
      current.appendChild(node);
      current = node;
      continue;
    } // Text nodes are leaf nodes.


    current.appendChild(window.document.createTextNode(unescape(t)));
  }

  return rootDiv;
} // This is a list of all the Unicode characters that have a strong
// right-to-left category. What this means is that these characters are
// written right-to-left for sure. It was generated by pulling all the strong
// right-to-left characters out of the Unicode data table. That table can
// found at: http://www.unicode.org/Public/UNIDATA/UnicodeData.txt


var strongRTLRanges = [[1470, 1470], [1472, 1472], [1475, 1475], [1478, 1478], [1488, 1514], [1520, 1524], [1544, 1544], [1547, 1547], [1549, 1549], [1563, 1563], [1566, 1610], [1645, 1647], [1649, 1749], [1765, 1766], [1774, 1775], [1786, 1805], [1807, 1808], [1810, 1839], [1869, 1957], [1969, 1969], [1984, 2026], [2036, 2037], [2042, 2042], [2048, 2069], [2074, 2074], [2084, 2084], [2088, 2088], [2096, 2110], [2112, 2136], [2142, 2142], [2208, 2208], [2210, 2220], [8207, 8207], [64285, 64285], [64287, 64296], [64298, 64310], [64312, 64316], [64318, 64318], [64320, 64321], [64323, 64324], [64326, 64449], [64467, 64829], [64848, 64911], [64914, 64967], [65008, 65020], [65136, 65140], [65142, 65276], [67584, 67589], [67592, 67592], [67594, 67637], [67639, 67640], [67644, 67644], [67647, 67669], [67671, 67679], [67840, 67867], [67872, 67897], [67903, 67903], [67968, 68023], [68030, 68031], [68096, 68096], [68112, 68115], [68117, 68119], [68121, 68147], [68160, 68167], [68176, 68184], [68192, 68223], [68352, 68405], [68416, 68437], [68440, 68466], [68472, 68479], [68608, 68680], [126464, 126467], [126469, 126495], [126497, 126498], [126500, 126500], [126503, 126503], [126505, 126514], [126516, 126519], [126521, 126521], [126523, 126523], [126530, 126530], [126535, 126535], [126537, 126537], [126539, 126539], [126541, 126543], [126545, 126546], [126548, 126548], [126551, 126551], [126553, 126553], [126555, 126555], [126557, 126557], [126559, 126559], [126561, 126562], [126564, 126564], [126567, 126570], [126572, 126578], [126580, 126583], [126585, 126588], [126590, 126590], [126592, 126601], [126603, 126619], [126625, 126627], [126629, 126633], [126635, 126651], [1114109, 1114109]];

function isStrongRTLChar(charCode) {
  for (var i = 0; i < strongRTLRanges.length; i++) {
    var currentRange = strongRTLRanges[i];

    if (charCode >= currentRange[0] && charCode <= currentRange[1]) {
      return true;
    }
  }

  return false;
}

function determineBidi(cueDiv) {
  var nodeStack = [],
      text = "",
      charCode;

  if (!cueDiv || !cueDiv.childNodes) {
    return "ltr";
  }

  function pushNodes(nodeStack, node) {
    for (var i = node.childNodes.length - 1; i >= 0; i--) {
      nodeStack.push(node.childNodes[i]);
    }
  }

  function nextTextNode(nodeStack) {
    if (!nodeStack || !nodeStack.length) {
      return null;
    }

    var node = nodeStack.pop(),
        text = node.textContent || node.innerText;

    if (text) {
      // TODO: This should match all unicode type B characters (paragraph
      // separator characters). See issue #115.
      var m = text.match(/^.*(\n|\r)/);

      if (m) {
        nodeStack.length = 0;
        return m[0];
      }

      return text;
    }

    if (node.tagName === "ruby") {
      return nextTextNode(nodeStack);
    }

    if (node.childNodes) {
      pushNodes(nodeStack, node);
      return nextTextNode(nodeStack);
    }
  }

  pushNodes(nodeStack, cueDiv);

  while (text = nextTextNode(nodeStack)) {
    for (var i = 0; i < text.length; i++) {
      charCode = text.charCodeAt(i);

      if (isStrongRTLChar(charCode)) {
        return "rtl";
      }
    }
  }

  return "ltr";
}

function computeLinePos(cue) {
  if (typeof cue.line === "number" && (cue.snapToLines || cue.line >= 0 && cue.line <= 100)) {
    return cue.line;
  }

  if (!cue.track || !cue.track.textTrackList || !cue.track.textTrackList.mediaElement) {
    return -1;
  }

  var track = cue.track,
      trackList = track.textTrackList,
      count = 0;

  for (var i = 0; i < trackList.length && trackList[i] !== track; i++) {
    if (trackList[i].mode === "showing") {
      count++;
    }
  }

  return ++count * -1;
}

function StyleBox() {} // Apply styles to a div. If there is no div passed then it defaults to the
// div on 'this'.


StyleBox.prototype.applyStyles = function (styles, div) {
  div = div || (this || _global).div;

  for (var prop in styles) {
    if (styles.hasOwnProperty(prop)) {
      div.style[prop] = styles[prop];
    }
  }
};

StyleBox.prototype.formatStyle = function (val, unit) {
  return val === 0 ? 0 : val + unit;
}; // Constructs the computed display state of the cue (a div). Places the div
// into the overlay which should be a block level element (usually a div).


function CueStyleBox(window, cue, styleOptions) {
  StyleBox.call(this || _global);
  (this || _global).cue = cue; // Parse our cue's text into a DOM tree rooted at 'cueDiv'. This div will
  // have inline positioning and will function as the cue background box.

  (this || _global).cueDiv = parseContent(window, cue.text);
  var styles = {
    color: "rgba(255, 255, 255, 1)",
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    position: "relative",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "inline",
    writingMode: cue.vertical === "" ? "horizontal-tb" : cue.vertical === "lr" ? "vertical-lr" : "vertical-rl",
    unicodeBidi: "plaintext"
  };
  this.applyStyles(styles, (this || _global).cueDiv); // Create an absolutely positioned div that will be used to position the cue
  // div. Note, all WebVTT cue-setting alignments are equivalent to the CSS
  // mirrors of them except middle instead of center on Safari.

  (this || _global).div = window.document.createElement("div");
  styles = {
    direction: determineBidi((this || _global).cueDiv),
    writingMode: cue.vertical === "" ? "horizontal-tb" : cue.vertical === "lr" ? "vertical-lr" : "vertical-rl",
    unicodeBidi: "plaintext",
    textAlign: cue.align === "middle" ? "center" : cue.align,
    font: styleOptions.font,
    whiteSpace: "pre-line",
    position: "absolute"
  };
  this.applyStyles(styles);

  (this || _global).div.appendChild((this || _global).cueDiv); // Calculate the distance from the reference edge of the viewport to the text
  // position of the cue box. The reference edge will be resolved later when
  // the box orientation styles are applied.


  var textPos = 0;

  switch (cue.positionAlign) {
    case "start":
      textPos = cue.position;
      break;

    case "center":
      textPos = cue.position - cue.size / 2;
      break;

    case "end":
      textPos = cue.position - cue.size;
      break;
  } // Horizontal box orientation; textPos is the distance from the left edge of the
  // area to the left edge of the box and cue.size is the distance extending to
  // the right from there.


  if (cue.vertical === "") {
    this.applyStyles({
      left: this.formatStyle(textPos, "%"),
      width: this.formatStyle(cue.size, "%")
    }); // Vertical box orientation; textPos is the distance from the top edge of the
    // area to the top edge of the box and cue.size is the height extending
    // downwards from there.
  } else {
    this.applyStyles({
      top: this.formatStyle(textPos, "%"),
      height: this.formatStyle(cue.size, "%")
    });
  }

  (this || _global).move = function (box) {
    this.applyStyles({
      top: this.formatStyle(box.top, "px"),
      bottom: this.formatStyle(box.bottom, "px"),
      left: this.formatStyle(box.left, "px"),
      right: this.formatStyle(box.right, "px"),
      height: this.formatStyle(box.height, "px"),
      width: this.formatStyle(box.width, "px")
    });
  };
}

CueStyleBox.prototype = _objCreate(StyleBox.prototype);
CueStyleBox.prototype.constructor = CueStyleBox; // Represents the co-ordinates of an Element in a way that we can easily
// compute things with such as if it overlaps or intersects with another Element.
// Can initialize it with either a StyleBox or another BoxPosition.

function BoxPosition(obj) {
  // Either a BoxPosition was passed in and we need to copy it, or a StyleBox
  // was passed in and we need to copy the results of 'getBoundingClientRect'
  // as the object returned is readonly. All co-ordinate values are in reference
  // to the viewport origin (top left).
  var lh, height, width, top;

  if (obj.div) {
    height = obj.div.offsetHeight;
    width = obj.div.offsetWidth;
    top = obj.div.offsetTop;
    var rects = (rects = obj.div.childNodes) && (rects = rects[0]) && rects.getClientRects && rects.getClientRects();
    obj = obj.div.getBoundingClientRect(); // In certain cases the outter div will be slightly larger then the sum of
    // the inner div's lines. This could be due to bold text, etc, on some platforms.
    // In this case we should get the average line height and use that. This will
    // result in the desired behaviour.

    lh = rects ? Math.max(rects[0] && rects[0].height || 0, obj.height / rects.length) : 0;
  }

  (this || _global).left = obj.left;
  (this || _global).right = obj.right;
  (this || _global).top = obj.top || top;
  (this || _global).height = obj.height || height;
  (this || _global).bottom = obj.bottom || top + (obj.height || height);
  (this || _global).width = obj.width || width;
  (this || _global).lineHeight = lh !== undefined ? lh : obj.lineHeight;
} // Move the box along a particular axis. Optionally pass in an amount to move
// the box. If no amount is passed then the default is the line height of the
// box.


BoxPosition.prototype.move = function (axis, toMove) {
  toMove = toMove !== undefined ? toMove : (this || _global).lineHeight;

  switch (axis) {
    case "+x":
      (this || _global).left += toMove;
      (this || _global).right += toMove;
      break;

    case "-x":
      (this || _global).left -= toMove;
      (this || _global).right -= toMove;
      break;

    case "+y":
      (this || _global).top += toMove;
      (this || _global).bottom += toMove;
      break;

    case "-y":
      (this || _global).top -= toMove;
      (this || _global).bottom -= toMove;
      break;
  }
}; // Check if this box overlaps another box, b2.


BoxPosition.prototype.overlaps = function (b2) {
  return (this || _global).left < b2.right && (this || _global).right > b2.left && (this || _global).top < b2.bottom && (this || _global).bottom > b2.top;
}; // Check if this box overlaps any other boxes in boxes.


BoxPosition.prototype.overlapsAny = function (boxes) {
  for (var i = 0; i < boxes.length; i++) {
    if (this.overlaps(boxes[i])) {
      return true;
    }
  }

  return false;
}; // Check if this box is within another box.


BoxPosition.prototype.within = function (container) {
  return (this || _global).top >= container.top && (this || _global).bottom <= container.bottom && (this || _global).left >= container.left && (this || _global).right <= container.right;
}; // Check if this box is entirely within the container or it is overlapping
// on the edge opposite of the axis direction passed. For example, if "+x" is
// passed and the box is overlapping on the left edge of the container, then
// return true.


BoxPosition.prototype.overlapsOppositeAxis = function (container, axis) {
  switch (axis) {
    case "+x":
      return (this || _global).left < container.left;

    case "-x":
      return (this || _global).right > container.right;

    case "+y":
      return (this || _global).top < container.top;

    case "-y":
      return (this || _global).bottom > container.bottom;
  }
}; // Find the percentage of the area that this box is overlapping with another
// box.


BoxPosition.prototype.intersectPercentage = function (b2) {
  var x = Math.max(0, Math.min((this || _global).right, b2.right) - Math.max((this || _global).left, b2.left)),
      y = Math.max(0, Math.min((this || _global).bottom, b2.bottom) - Math.max((this || _global).top, b2.top)),
      intersectArea = x * y;
  return intersectArea / ((this || _global).height * (this || _global).width);
}; // Convert the positions from this box to CSS compatible positions using
// the reference container's positions. This has to be done because this
// box's positions are in reference to the viewport origin, whereas, CSS
// values are in referecne to their respective edges.


BoxPosition.prototype.toCSSCompatValues = function (reference) {
  return {
    top: (this || _global).top - reference.top,
    bottom: reference.bottom - (this || _global).bottom,
    left: (this || _global).left - reference.left,
    right: reference.right - (this || _global).right,
    height: (this || _global).height,
    width: (this || _global).width
  };
}; // Get an object that represents the box's position without anything extra.
// Can pass a StyleBox, HTMLElement, or another BoxPositon.


BoxPosition.getSimpleBoxPosition = function (obj) {
  var height = obj.div ? obj.div.offsetHeight : obj.tagName ? obj.offsetHeight : 0;
  var width = obj.div ? obj.div.offsetWidth : obj.tagName ? obj.offsetWidth : 0;
  var top = obj.div ? obj.div.offsetTop : obj.tagName ? obj.offsetTop : 0;
  obj = obj.div ? obj.div.getBoundingClientRect() : obj.tagName ? obj.getBoundingClientRect() : obj;
  var ret = {
    left: obj.left,
    right: obj.right,
    top: obj.top || top,
    height: obj.height || height,
    bottom: obj.bottom || top + (obj.height || height),
    width: obj.width || width
  };
  return ret;
}; // Move a StyleBox to its specified, or next best, position. The containerBox
// is the box that contains the StyleBox, such as a div. boxPositions are
// a list of other boxes that the styleBox can't overlap with.


function moveBoxToLinePosition(window, styleBox, containerBox, boxPositions) {
  // Find the best position for a cue box, b, on the video. The axis parameter
  // is a list of axis, the order of which, it will move the box along. For example:
  // Passing ["+x", "-x"] will move the box first along the x axis in the positive
  // direction. If it doesn't find a good position for it there it will then move
  // it along the x axis in the negative direction.
  function findBestPosition(b, axis) {
    var bestPosition,
        specifiedPosition = new BoxPosition(b),
        percentage = 1; // Highest possible so the first thing we get is better.

    for (var i = 0; i < axis.length; i++) {
      while (b.overlapsOppositeAxis(containerBox, axis[i]) || b.within(containerBox) && b.overlapsAny(boxPositions)) {
        b.move(axis[i]);
      } // We found a spot where we aren't overlapping anything. This is our
      // best position.


      if (b.within(containerBox)) {
        return b;
      }

      var p = b.intersectPercentage(containerBox); // If we're outside the container box less then we were on our last try
      // then remember this position as the best position.

      if (percentage > p) {
        bestPosition = new BoxPosition(b);
        percentage = p;
      } // Reset the box position to the specified position.


      b = new BoxPosition(specifiedPosition);
    }

    return bestPosition || specifiedPosition;
  }

  var boxPosition = new BoxPosition(styleBox),
      cue = styleBox.cue,
      linePos = computeLinePos(cue),
      axis = []; // If we have a line number to align the cue to.

  if (cue.snapToLines) {
    var size;

    switch (cue.vertical) {
      case "":
        axis = ["+y", "-y"];
        size = "height";
        break;

      case "rl":
        axis = ["+x", "-x"];
        size = "width";
        break;

      case "lr":
        axis = ["-x", "+x"];
        size = "width";
        break;
    }

    var step = boxPosition.lineHeight,
        position = step * Math.round(linePos),
        maxPosition = containerBox[size] + step,
        initialAxis = axis[0]; // If the specified intial position is greater then the max position then
    // clamp the box to the amount of steps it would take for the box to
    // reach the max position.

    if (Math.abs(position) > maxPosition) {
      position = position < 0 ? -1 : 1;
      position *= Math.ceil(maxPosition / step) * step;
    } // If computed line position returns negative then line numbers are
    // relative to the bottom of the video instead of the top. Therefore, we
    // need to increase our initial position by the length or width of the
    // video, depending on the writing direction, and reverse our axis directions.


    if (linePos < 0) {
      position += cue.vertical === "" ? containerBox.height : containerBox.width;
      axis = axis.reverse();
    } // Move the box to the specified position. This may not be its best
    // position.


    boxPosition.move(initialAxis, position);
  } else {
    // If we have a percentage line value for the cue.
    var calculatedPercentage = boxPosition.lineHeight / containerBox.height * 100;

    switch (cue.lineAlign) {
      case "center":
        linePos -= calculatedPercentage / 2;
        break;

      case "end":
        linePos -= calculatedPercentage;
        break;
    } // Apply initial line position to the cue box.


    switch (cue.vertical) {
      case "":
        styleBox.applyStyles({
          top: styleBox.formatStyle(linePos, "%")
        });
        break;

      case "rl":
        styleBox.applyStyles({
          left: styleBox.formatStyle(linePos, "%")
        });
        break;

      case "lr":
        styleBox.applyStyles({
          right: styleBox.formatStyle(linePos, "%")
        });
        break;
    }

    axis = ["+y", "-x", "+x", "-y"]; // Get the box position again after we've applied the specified positioning
    // to it.

    boxPosition = new BoxPosition(styleBox);
  }

  var bestPosition = findBestPosition(boxPosition, axis);
  styleBox.move(bestPosition.toCSSCompatValues(containerBox));
}

function WebVTT() {// Nothing
} // Helper to allow strings to be decoded instead of the default binary utf8 data.


WebVTT.StringDecoder = function () {
  return {
    decode: function (data) {
      if (!data) {
        return "";
      }

      if (typeof data !== "string") {
        throw new Error("Error - expected string data.");
      }

      return decodeURIComponent(encodeURIComponent(data));
    }
  };
};

WebVTT.convertCueToDOMTree = function (window, cuetext) {
  if (!window || !cuetext) {
    return null;
  }

  return parseContent(window, cuetext);
};

var FONT_SIZE_PERCENT = 0.05;
var FONT_STYLE = "sans-serif";
var CUE_BACKGROUND_PADDING = "1.5%"; // Runs the processing model over the cues and regions passed to it.
// @param overlay A block level element (usually a div) that the computed cues
//                and regions will be placed into.

WebVTT.processCues = function (window, cues, overlay) {
  if (!window || !cues || !overlay) {
    return null;
  } // Remove all previous children.


  while (overlay.firstChild) {
    overlay.removeChild(overlay.firstChild);
  }

  var paddedOverlay = window.document.createElement("div");
  paddedOverlay.style.position = "absolute";
  paddedOverlay.style.left = "0";
  paddedOverlay.style.right = "0";
  paddedOverlay.style.top = "0";
  paddedOverlay.style.bottom = "0";
  paddedOverlay.style.margin = CUE_BACKGROUND_PADDING;
  overlay.appendChild(paddedOverlay); // Determine if we need to compute the display states of the cues. This could
  // be the case if a cue's state has been changed since the last computation or
  // if it has not been computed yet.

  function shouldCompute(cues) {
    for (var i = 0; i < cues.length; i++) {
      if (cues[i].hasBeenReset || !cues[i].displayState) {
        return true;
      }
    }

    return false;
  } // We don't need to recompute the cues' display states. Just reuse them.


  if (!shouldCompute(cues)) {
    for (var i = 0; i < cues.length; i++) {
      paddedOverlay.appendChild(cues[i].displayState);
    }

    return;
  }

  var boxPositions = [],
      containerBox = BoxPosition.getSimpleBoxPosition(paddedOverlay),
      fontSize = Math.round(containerBox.height * FONT_SIZE_PERCENT * 100) / 100;
  var styleOptions = {
    font: fontSize + "px " + FONT_STYLE
  };

  (function () {
    var styleBox, cue;

    for (var i = 0; i < cues.length; i++) {
      cue = cues[i]; // Compute the intial position and styles of the cue div.

      styleBox = new CueStyleBox(window, cue, styleOptions);
      paddedOverlay.appendChild(styleBox.div); // Move the cue div to it's correct line position.

      moveBoxToLinePosition(window, styleBox, containerBox, boxPositions); // Remember the computed div so that we don't have to recompute it later
      // if we don't have too.

      cue.displayState = styleBox.div;
      boxPositions.push(BoxPosition.getSimpleBoxPosition(styleBox));
    }
  })();
};

WebVTT.Parser = function (window, vttjs, decoder) {
  if (!decoder) {
    decoder = vttjs;
    vttjs = {};
  }

  if (!vttjs) {
    vttjs = {};
  }

  (this || _global).window = window;
  (this || _global).vttjs = vttjs;
  (this || _global).state = "INITIAL";
  (this || _global).buffer = "";
  (this || _global).decoder = decoder || new TextDecoder("utf8");
  (this || _global).regionList = [];
};

WebVTT.Parser.prototype = {
  // If the error is a ParsingError then report it to the consumer if
  // possible. If it's not a ParsingError then throw it like normal.
  reportOrThrowError: function (e) {
    if (e instanceof ParsingError) {
      (this || _global).onparsingerror && this.onparsingerror(e);
    } else {
      throw e;
    }
  },
  parse: function (data) {
    var self = this || _global; // If there is no data then we won't decode it, but will just try to parse
    // whatever is in buffer already. This may occur in circumstances, for
    // example when flush() is called.

    if (data) {
      // Try to decode the data that we received.
      self.buffer += self.decoder.decode(data, {
        stream: true
      });
    }

    function collectNextLine() {
      var buffer = self.buffer;
      var pos = 0;

      while (pos < buffer.length && buffer[pos] !== "\r" && buffer[pos] !== "\n") {
        ++pos;
      }

      var line = buffer.substr(0, pos); // Advance the buffer early in case we fail below.

      if (buffer[pos] === "\r") {
        ++pos;
      }

      if (buffer[pos] === "\n") {
        ++pos;
      }

      self.buffer = buffer.substr(pos);
      return line;
    } // 3.4 WebVTT region and WebVTT region settings syntax


    function parseRegion(input) {
      var settings = new Settings();
      parseOptions(input, function (k, v) {
        switch (k) {
          case "id":
            settings.set(k, v);
            break;

          case "width":
            settings.percent(k, v);
            break;

          case "lines":
            settings.integer(k, v);
            break;

          case "regionanchor":
          case "viewportanchor":
            var xy = v.split(",");

            if (xy.length !== 2) {
              break;
            } // We have to make sure both x and y parse, so use a temporary
            // settings object here.


            var anchor = new Settings();
            anchor.percent("x", xy[0]);
            anchor.percent("y", xy[1]);

            if (!anchor.has("x") || !anchor.has("y")) {
              break;
            }

            settings.set(k + "X", anchor.get("x"));
            settings.set(k + "Y", anchor.get("y"));
            break;

          case "scroll":
            settings.alt(k, v, ["up"]);
            break;
        }
      }, /=/, /\s/); // Create the region, using default values for any values that were not
      // specified.

      if (settings.has("id")) {
        var region = new (self.vttjs.VTTRegion || self.window.VTTRegion)();
        region.width = settings.get("width", 100);
        region.lines = settings.get("lines", 3);
        region.regionAnchorX = settings.get("regionanchorX", 0);
        region.regionAnchorY = settings.get("regionanchorY", 100);
        region.viewportAnchorX = settings.get("viewportanchorX", 0);
        region.viewportAnchorY = settings.get("viewportanchorY", 100);
        region.scroll = settings.get("scroll", ""); // Register the region.

        self.onregion && self.onregion(region); // Remember the VTTRegion for later in case we parse any VTTCues that
        // reference it.

        self.regionList.push({
          id: settings.get("id"),
          region: region
        });
      }
    } // draft-pantos-http-live-streaming-20
    // https://tools.ietf.org/html/draft-pantos-http-live-streaming-20#section-3.5
    // 3.5 WebVTT


    function parseTimestampMap(input) {
      var settings = new Settings();
      parseOptions(input, function (k, v) {
        switch (k) {
          case "MPEGT":
            settings.integer(k + "S", v);
            break;

          case "LOCA":
            settings.set(k + "L", parseTimeStamp(v));
            break;
        }
      }, /[^\d]:/, /,/);
      self.ontimestampmap && self.ontimestampmap({
        "MPEGTS": settings.get("MPEGTS"),
        "LOCAL": settings.get("LOCAL")
      });
    } // 3.2 WebVTT metadata header syntax


    function parseHeader(input) {
      if (input.match(/X-TIMESTAMP-MAP/)) {
        // This line contains HLS X-TIMESTAMP-MAP metadata
        parseOptions(input, function (k, v) {
          switch (k) {
            case "X-TIMESTAMP-MAP":
              parseTimestampMap(v);
              break;
          }
        }, /=/);
      } else {
        parseOptions(input, function (k, v) {
          switch (k) {
            case "Region":
              // 3.3 WebVTT region metadata header syntax
              parseRegion(v);
              break;
          }
        }, /:/);
      }
    } // 5.1 WebVTT file parsing.


    try {
      var line;

      if (self.state === "INITIAL") {
        // We can't start parsing until we have the first line.
        if (!/\r\n|\n/.test(self.buffer)) {
          return this || _global;
        }

        line = collectNextLine();
        var m = line.match(/^WEBVTT([ \t].*)?$/);

        if (!m || !m[0]) {
          throw new ParsingError(ParsingError.Errors.BadSignature);
        }

        self.state = "HEADER";
      }

      var alreadyCollectedLine = false;

      while (self.buffer) {
        // We can't parse a line until we have the full line.
        if (!/\r\n|\n/.test(self.buffer)) {
          return this || _global;
        }

        if (!alreadyCollectedLine) {
          line = collectNextLine();
        } else {
          alreadyCollectedLine = false;
        }

        switch (self.state) {
          case "HEADER":
            // 13-18 - Allow a header (metadata) under the WEBVTT line.
            if (/:/.test(line)) {
              parseHeader(line);
            } else if (!line) {
              // An empty line terminates the header and starts the body (cues).
              self.state = "ID";
            }

            continue;

          case "NOTE":
            // Ignore NOTE blocks.
            if (!line) {
              self.state = "ID";
            }

            continue;

          case "ID":
            // Check for the start of NOTE blocks.
            if (/^NOTE($|[ \t])/.test(line)) {
              self.state = "NOTE";
              break;
            } // 19-29 - Allow any number of line terminators, then initialize new cue values.


            if (!line) {
              continue;
            }

            self.cue = new (self.vttjs.VTTCue || self.window.VTTCue)(0, 0, ""); // Safari still uses the old middle value and won't accept center

            try {
              self.cue.align = "center";
            } catch (e) {
              self.cue.align = "middle";
            }

            self.state = "CUE"; // 30-39 - Check if self line contains an optional identifier or timing data.

            if (line.indexOf("-->") === -1) {
              self.cue.id = line;
              continue;
            }

          // Process line as start of a cue.

          /*falls through*/

          case "CUE":
            // 40 - Collect cue timings and settings.
            try {
              parseCue(line, self.cue, self.regionList);
            } catch (e) {
              self.reportOrThrowError(e); // In case of an error ignore rest of the cue.

              self.cue = null;
              self.state = "BADCUE";
              continue;
            }

            self.state = "CUETEXT";
            continue;

          case "CUETEXT":
            var hasSubstring = line.indexOf("-->") !== -1; // 34 - If we have an empty line then report the cue.
            // 35 - If we have the special substring '-->' then report the cue,
            // but do not collect the line as we need to process the current
            // one as a new cue.

            if (!line || hasSubstring && (alreadyCollectedLine = true)) {
              // We are done parsing self cue.
              self.oncue && self.oncue(self.cue);
              self.cue = null;
              self.state = "ID";
              continue;
            }

            if (self.cue.text) {
              self.cue.text += "\n";
            }

            self.cue.text += line.replace(/\u2028/g, "\n").replace(/u2029/g, "\n");
            continue;

          case "BADCUE":
            // BADCUE
            // 54-62 - Collect and discard the remaining cue.
            if (!line) {
              self.state = "ID";
            }

            continue;
        }
      }
    } catch (e) {
      self.reportOrThrowError(e); // If we are currently parsing a cue, report what we have.

      if (self.state === "CUETEXT" && self.cue && self.oncue) {
        self.oncue(self.cue);
      }

      self.cue = null; // Enter BADWEBVTT state if header was not parsed correctly otherwise
      // another exception occurred so enter BADCUE state.

      self.state = self.state === "INITIAL" ? "BADWEBVTT" : "BADCUE";
    }

    return this || _global;
  },
  flush: function () {
    var self = this || _global;

    try {
      // Finish decoding the stream.
      self.buffer += self.decoder.decode(); // Synthesize the end of the current cue or region.

      if (self.cue || self.state === "HEADER") {
        self.buffer += "\n\n";
        self.parse();
      } // If we've flushed, parsed, and we're still on the INITIAL state then
      // that means we don't have enough of the stream to parse the first
      // line.


      if (self.state === "INITIAL") {
        throw new ParsingError(ParsingError.Errors.BadSignature);
      }
    } catch (e) {
      self.reportOrThrowError(e);
    }

    self.onflush && self.onflush();
    return this || _global;
  }
};
exports = WebVTT;
export default exports;